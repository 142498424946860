import { SetStateAction } from "react"
import '../../../assets/css/create-resource.css'

export const CreateBankAccounts = (
  isEdit: boolean, 
  isCreate: boolean, 
  inpName: string, 
  inpBestDay: number | undefined,
  inpCredit: number | undefined,
  inpDueDay: number | undefined,
  inpLimitCredit: number | undefined,
  inpDebit: number | undefined,
  setInpName: React.Dispatch<SetStateAction<string>>,
  setInpBestDay: React.Dispatch<SetStateAction<number | undefined>>,
  setInpCredit: React.Dispatch<SetStateAction<number | undefined>>,
  setInpDueDay: React.Dispatch<SetStateAction<number | undefined>>,
  setLimitCredit: React.Dispatch<SetStateAction<number | undefined>>,
  setInpDebit: React.Dispatch<SetStateAction<number | undefined>>,
  ) => (
<div className={isCreate || isEdit? 'div-create' : 'div-none'}>
  <h2>{isEdit ? 'Edit' : 'Create'}</h2>
  <div className="div-desc-input">
    <label>Nome:</label>
    <input type="text" value={inpName} onChange={e => setInpName(e.target.value)} />
  </div>
  <div className="div-desc-input">
    <label>Melhor dia de compra:</label>
    <input type="number"  value={inpBestDay} onChange={e=> setInpBestDay(Number(e.target.value))}/>
  </div>
  <div className="div-desc-input">
    <label>Vencimento da Fatura:</label>
    <input type="number"  value={inpDueDay} onChange={e=> setInpDueDay(Number(e.target.value))}/>
  </div>
</div>)
