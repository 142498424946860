/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
// import { parseDateByApi, parseValueByApi } from "../../hooks";
import { BankAccountsType } from "../Resources";
import api from "../../services/api";
import { SetStateAction, useState } from "react";
import { ConfirmPopup } from "../PopUps";
import { BsTrash2Fill } from "react-icons/bs";

export const LiBankAccounts: React.FC<{
  index: number
  resource: BankAccountsType, 
  resources: BankAccountsType[], 
  setResource: React.Dispatch<SetStateAction<BankAccountsType[]>>,
  setUpdateResource: (arg: boolean) => void
  setHandleEdit: (id: string) => void
}> = ({
  index,
  resource, 
  resources, 
  setResource,
  setUpdateResource,
  setHandleEdit
}) => {
  const {
    id,
    name,
    bestDay,
    dueDay,
  } = resource
  const now = new Date()
  const month = (now.getMonth()).toString().padStart(2,'0')

  const [showPopup, setShowPopup] = useState(false);
  
  const handleRemove = () => {
    setShowPopup(true)
  }
  const handleCancel = () => {
    setShowPopup(false)
  }

  const handleConfirmRemove = ()  => {
    setUpdateResource(false)
    setShowPopup(false);
    api.delete(`/bankAccounts/${id}`).then(() => {
      // alert('Removido com sucesso')
      const expenseIndex = resources.findIndex(({id: exenseseId}) => exenseseId === id )
      if (expenseIndex > -1) {
        resources.splice(expenseIndex, 1)
        setResource(resources)
        setUpdateResource(true)
      }
    })
    .catch(err => {
      console.error(err)
      alert('Error :(')
    })
  }
  const msg = `Tem certeza que deseja excluir despensa ${name} ?`
  return (
    <div className="">
      {
      showPopup && 
        <ConfirmPopup 
        message={msg}
        onConfirm={handleConfirmRemove}
        onCancel={handleCancel}
        isDelete={true}
        />
      }
      <li key={resource.id}>
        <div className="li-item">
          <div className="item-description">{name.toUpperCase()}</div>
          <div className="item-value">{(bestDay.toString().padStart(2, '0'))}/{month}</div>
          <div className="item-value">{(dueDay.toString().padStart(2, '0'))}/{month}</div>
          <a className="item-details"  onClick={e=> setHandleEdit(resource.id)}>Details</a>
          <div className="item-remove" onClick={handleRemove}><BsTrash2Fill  /></div>
        </div>
      {/* moblie */}
        <div key={resource.id} className="div-moblie">
          <a key={resource.id} className="li-item-m" onClick={e=> setHandleEdit(resource.id)}>
          <div className="li-m-p1">
            <div>{name.toUpperCase()}</div>
            </div>
            <div className="li-m-p2">
            <div>Melhor dia: {(bestDay.toString().padStart(2, '0'))}/{month}</div>
            <div>Venc.: {(dueDay.toString().padStart(2, '0'))}/{month}</div>
            </div>
          </a>
          <div className="item-remove" onClick={handleRemove}><BsTrash2Fill  /></div>
        </div>
      </li>
    </div>
  );
}
