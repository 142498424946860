import { BsCreditCard2Front, BsCashCoin  } from "react-icons/bs"
import { AiFillBank } from "react-icons/ai"
import { FaHome } from "react-icons/fa"
import { Link } from "react-router-dom"

export const NavBar = () => (
<div className='side-menu'> 
  <Link to={'/'}> <label> Home </label> <FaHome /></Link>
  <Link to={'/expenses'}> <label> Despesas  </label> <BsCashCoin /></Link>
  <Link to={'/bankAccounts'}> <label> Cartões  </label> <BsCreditCard2Front /></Link>
  {/* <Link to={'/qrcode'}> <label> test  </label> QR</Link> */}
</div>
)
