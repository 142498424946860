import React from 'react';
import '../../assets/css/header.css'; // Você pode criar um CSS específico para o Header
import { useAuth } from '../../contexts';
import { FiLogOut } from 'react-icons/fi';

export const Header: React.FC = () => {
  const {isAuthenticated, logout, getUserName} = useAuth()
  const handleClick = () => {
    if(isAuthenticated && logout) logout()
  }
  return (
    <div className="header">
      <div className="logo" onClick={e=> window.location.href ='/'}>
        <label> logo </label>
      </div>
      {isAuthenticated ? 
      <div className='user-login'>
        <div>{getUserName().toUpperCase()}</div>

        <div className='bt-logout' onClick={handleClick}> 
          <label>Logout</label> 
          <FiLogOut/>
        </div>
      </div>
      : ''
      }
    </div>
  );
};
