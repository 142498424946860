import { useParams } from "react-router-dom";
import { BankAccountsList } from "./bank-accounts-list";
import { BankAccountsById } from "./bank-accounts-by-id";
import { useEffect, useState } from "react";
import api from "../../../services/api";

export const BankAccounts = () => {
  const [apiOk, setApiOk] = useState('')
  useEffect(() => {
    api.get('/healthcheck').then(({status})=> {
      if(status === 200) {
        setApiOk('ok')
      }
    })
    .catch(err => setApiOk('error'))
  }, [])
  
  const { id } = useParams<{ id?: string }>();
  switch (apiOk) {
    case 'ok': 
      return !id ? <BankAccountsList/> : <BankAccountsById />
    case 'error': 
      return <div>ERROR</div>
    default: 
      return <div>Await API </div>
  }
  
}

export type BankAccountsType = {
  id: string,
  name: string,
  limitCredit:number,
  credit:number,
  debit:number,
  bestDay:number,
  dueDay:number,
  }
