import React, { useEffect, useState } from "react"
import md5 from "md5";
import { useAuth } from '../../contexts';
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importa os ícones
import '../../assets/css/login.css'
import imgBanner  from '../../assets/imgs/coins-grass.jpg'

export const Login: React.FC = () => {
  const[passwordVisible, setPasswordVisible] = useState(false);
  const { login, isAuthenticated } = useAuth();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isApiOk, setIsApiOk] = useState(false)
  const [isClick, setIsClick] = useState(false)
  const [classBtLogin, setClassBtLogin] = useState('bt-login-disabled')

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate('/'); 
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    api.get('/healthcheck').then(({status})=> {
      if(status === 200) {
        setIsApiOk(true)
      }
    })
  }, [])

  useEffect(()=>{
    login(username, md5(password))
  }, [isClick])

  useEffect(()=>{
    if(username.length && password.length) {
      setClassBtLogin('bt-login')
    }
  }, [username, password])

  return (
    <div className="login-container">
        <img className='banner-img-login' src={imgBanner} alt="banner"/>
      <div className='form-login'>
        <div className='div-inputs'>
          <h1> Acesse sua Conta</h1>
          <div className='div-input-login'>
            <label> Username</label>
            <div>
              <input onChange={e=>setUsername(e.target.value)} value={username}/>
            </div>
          </div>
          <div className='div-input-login'>
            <label> Password </label>
            <div>
              <input type={passwordVisible? 'text' :'password'} value={password} onChange={e=> setPassword(e.target.value)}/> 
              {passwordVisible 
              ? <FaEyeSlash onClick={e=>setPasswordVisible(!passwordVisible)}/>  
              : <FaEye onClick={e=>setPasswordVisible(!passwordVisible)} />
              } </div>
          </div>
          {/* <label>Esquci a senha</label> */}
          {
          isApiOk
            ? <button className={classBtLogin} onClick={e=> setIsClick(!isClick)}> Login </button>
            : <label>Await Api</label>
          }
        </div>
      </div>
    </div>
  );
};
