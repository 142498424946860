// AuthContext.tsx
import React, 
{ 
  createContext, 
  useContext, 
  useEffect, 
  useState
} from 'react';
import { 
  isAuthenticated as isAuthenticatedAPI,
  login as setToken,
  logout as removeToken,
  setStorage,
  getStorage,
  cleanStorage
} from '../services/auth'
import api from '../services/api';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, passMd5: string) => void;
  logout: () => void;
  getUserName: () => string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isAuthenticatedAPI());
  const [username, setUsername] =useState<String | null>(null)
  const [passMd5, setPassMd5] = useState<String | null>(null)
  const [expire] = useState(getStorage('expireIn'))
  const expireIn = expire && !Number.isNaN(Number(expire)) ? Number(expire) : 0
  
  useEffect(() => {
    if(username && passMd5) {
      api.post('/login', { username, passMd5 }).then(({ data }) => {
        Object.keys(data).forEach(field => {
          if(field === 'accessToken') {
            setToken(data[field])
          } else {
            setStorage(field, data[field])
          }
        })
        setIsAuthenticated(true)
      })
    }
  }, [username, passMd5])

  const login = (userLogin: string, passLogin: string) => {
    setUsername(userLogin)
    setPassMd5(passLogin)
  }
  const logout = () => {
    removeToken()
    cleanStorage()
    setIsAuthenticated(false)
  };

  const getUserName = () => {
    return getStorage('username') || 'User'
  }

  if (isAuthenticated && expireIn && Date.now() >= expireIn) {
    console.log('Expired token', Date.now(), expireIn)
    logout()
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, getUserName }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
