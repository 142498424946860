import React from 'react';
import '../../assets/css/popup.css'

interface ConfirmPopupProps {
  message: string;
  isError:boolean
}

export const NotificationPopup: React.FC<ConfirmPopupProps> = ({ message, isError }) => {
  const classPopup = `notification-popup${isError ? '-error' : ''}`
  const classContainer = `container-notification${isError ? '-error' : ''}`
  return (
    <div  className={classContainer}>
      <div className={classPopup}>
        <p>{message}</p>
      </div>
    </div>
  );
};
