/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Link } from "react-router-dom";
import { parseDateByApi, parseValueByApi } from "../../hooks";
import { ExpensesType } from "../Resources";
import { BsTrash2Fill } from "react-icons/bs";
import api from "../../services/api";
import { SetStateAction, useState } from "react";
import { ConfirmPopup } from "../PopUps";



export const LiExpenses: React.FC<{
  index: number
  resource: ExpensesType, 
  resources: ExpensesType[], 
  setResource: React.Dispatch<SetStateAction<ExpensesType[]>>,
  setUpdateResource: (arg: boolean) => void
  setHandleEdit: (id: string) => void
}> = ({
  index,
  resource, 
  resources, 
  setResource,
  setUpdateResource,
  setHandleEdit
}) => {
  const {
    id,
    description,
    currentInstallment,
    installments,
    value,
    isPaid,
    bankAccount,
    date
  } = resource
  const name = `${description}${installments > 1 ? ` ${currentInstallment}/${installments}` : ''}`.toUpperCase()
  const [showPopup, setShowPopup] = useState(false);
  
  const handleRemove = () => {
    setShowPopup(true)
  }
  const handleCancel = () => {
    setShowPopup(false)
  }

  const handleConfirmRemove = ()  => {
    setUpdateResource(false)
    setShowPopup(false);
    api.delete(`/expenses/${id}`).then(() => {
      // alert('Removido com sucesso')
      const expenseIndex = resources.findIndex(({id: exenseseId}) => exenseseId === id )
      if (expenseIndex > -1) {
        resources.splice(expenseIndex, 1)
        setResource(resources)
        setUpdateResource(true)
      }
    })
    .catch(err => {
      console.error(err)
      alert('Error :(')
    })
  }
  const msg = `Tem certeza que deseja excluir despensa ${name} ?`
  return (
    <div className="">
      {
      showPopup && 
        <ConfirmPopup 
        message={msg}
        onConfirm={handleConfirmRemove}
        onCancel={handleCancel}
        isDelete={true}
        />
      }
      <li key={resource.id}>
        <div className="li-item">
          <div className="item-date" title="Date">{parseDateByApi(date)}</div>
          <div className="item-description">
            <label>{name}</label>
            <div>
              <div className="item-bank-account">{bankAccount.name}</div>
            </div>
          </div>
          <div className="item-value">R$ {parseValueByApi(value)}</div>
          <div className="item-status" >{isPaid ? 'Pago' : 'Em Aberto'}</div>
          <a className="item-details"  onClick={e=> setHandleEdit(resource.id)}>Details</a>
          <div className="item-remove" onClick={handleRemove}><BsTrash2Fill  /></div>
        </div>
      {/* moblie */}
        <div key={resource.id} className="div-moblie">
          <a key={resource.id} className="li-item-m" onClick={e=> setHandleEdit(resource.id)}>
            <div className="li-m-p1">
              <div className="item-description-m">
                <label>{name}</label>
              </div>
              <div className="item-date-m" title="Date">{parseDateByApi(date)}</div>
            </div>
            <div className="li-m-p2">
              <div className="item-value-m">R$ {parseValueByApi(value)}</div>
              <div className="item-bank-account">{bankAccount.name}</div>
              <div className="item-status-m" >{isPaid ? 'Pago' : 'Em Aberto'}</div>
            </div>
          </a>
          <div className="item-remove" onClick={handleRemove}><BsTrash2Fill  /></div>
        </div>
      </li>
    </div>
  );
}
