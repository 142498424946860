import React from 'react';
import { Header } from '../Header/header';
import { Footer } from '../Footer/footer';
import { useAuth } from '../../contexts';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa'
import { BsCreditCard2Front } from "react-icons/bs";
import { NavBar } from './nav-bar';


export const BodyApp: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {isAuthenticated} = useAuth()
  return (
      <div className='container'>
        <Header />
          <div className={isAuthenticated ? 'body' : ''}>
          {
            isAuthenticated?
          <NavBar />
          : ''
          }
          <div className='body-app'>
            {children}
          </div>
        </div>
        <Footer />
      </div>
  );
};
