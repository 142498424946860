import { ChangeEventHandler, SetStateAction } from "react"
import { BankAccountsType } from "../Back-Accounts/bank-accounts"
import '../../../assets/css/create-resource.css'

export const CreateExpenses = (
  isEdit: boolean, 
  isCreate: boolean, 
  inpDescription: string, 
  inpValue: number | undefined, 
  inpDate: string, 
  inpBankId: string,
  inpCurrentIntallments:  number | undefined,
  inpInstallments:  number | undefined,
  inpNfNumber: string ,
  inpNfLink: string,
  setInpDescription: (arg0: string) => void,
  setInpValue:  React.Dispatch<SetStateAction<number | undefined>>,
  setInpDate: (arg0: string) => void,
  bankAccounts: BankAccountsType[],
  setInpInstallments: (arg0: number) => void,
  setInpCurrentIntallments: (arg0: number) => void,
  setInpNfNumber: (arg0: string) => void,
  setInpNfLink: (arg0: string) => void,
  handleSelectBankId: ChangeEventHandler<HTMLSelectElement> | undefined,
  ) => (
<div className={isCreate || isEdit? 'div-create' : 'div-none'}>
  <h2>{isEdit ? 'Edit' : 'Create'}</h2>
  <div className="div-desc-input">
    <label>Descrição:</label>
    <input type="text" value={inpDescription || ''} 
      onChange={e => setInpDescription(e.target.value)} 
    />
  </div>
  <div className="div-desc-input">
    <label>Valor:</label>
    <div>R$ 
      <input type="number"  
        value={inpValue || ''} 
        onChange={e=> setInpValue(Number(e.target.value))}
        />
    </div>
  </div>
  <div className="div-desc-input">
    <label>Data:</label>
    <input type="datetime-local" value={inpDate} onChange={e=> setInpDate(e.target.value)} />
  </div>
  <div className="div-desc-input">
    <label>Conta Bancária:</label>
    <select id="limit" value={inpBankId} onChange={handleSelectBankId}>
    <option value="">Selecione</option>
      {
        bankAccounts && bankAccounts.length &&
        bankAccounts.map(account => {
          return (
            <option value={account.id}>{account.name}</option>
          )
        } )

      }
    </select>
  </div>
  <div className="div-desc-input">
    <label>Quantidade de Parcelas:</label>
    <input 
      type="number" value={inpInstallments || ''} 
      onChange={e=>setInpInstallments(Number(e.target.value))} 
      />
    <span> </span>
  </div>
  <div className="div-desc-input">
    <label>Parcela Atual:</label>
    <input type="number" 
      value={inpCurrentIntallments || ''} 
      onChange={e=>setInpCurrentIntallments(Number(e.target.value))} 
      min={1}
      max={inpInstallments}
      />
    <span> </span>
  </div>
  <div className="div-desc-input">
    <label>Código da NF:</label>
    <input type="text" value={inpNfNumber} onChange={e=> setInpNfNumber(e.target.value)}  />
  </div>
  <div className="div-desc-input">
    <label>Link da NF:</label>
    <input type="url" value={inpNfLink} onChange={e=>setInpNfLink(e.target.value)} />
  </div>
  <div className="div-desc-input">
    {/* <label>isPaid</label> */}
    {/* <input type=""></input> */}
  </div>
</div>)
