import { SetStateAction, useEffect, useState } from "react"
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'
import api from '../../../services/api'
import { ExpensesType } from "./expenses"
import '../../../assets/css/resource.css'
import { LiExpenses } from "../../Li"
import { BankAccountsType } from "../Back-Accounts/bank-accounts"
import { CreateExpenses } from "./create-expense"
import { parseValueByApi, parseValueNumberByApi } from "../../../hooks"
import { AlertPopup, NotificationPopup } from "../../PopUps"
import { parseValueToApi } from "../../../hooks/parse-to-api"

export const ExpensesList = () => {
  const calcPages = (total: number, limit:number) => {
    const calc = Math.floor(total / limit) + (total % limit !== 0 ? 1 : 0)
    return calc || 1
  }
  const [expenses, setExpenses] = useState<ExpensesType[]>([])
  const [total, setTotal] = useState(0)
  const [sum, setSum] = useState(0)
  const [limit, setLimit] = useState(5)
  const [page, setPage] = useState(1)
  const [filter, /* setFilter */] = useState('')
  const [totalPages, setTotalPages] = useState(calcPages(total, limit))
  const [isCreate, setIsCreate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [bankAccounts, setBankAccounts] = useState<BankAccountsType[]>([])

  const [isErrorNotification, setIsErrorNotification] = useState(false)
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [msgNotificationPopup, setMsgNotificationPopup] = useState('');

  const [isErrorMsg, setIsErrorMsg] = useState(false)
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [msgAlertPopup, setMsgAlertPopup] = useState('');

  const [resourceId, setResourceId] = useState<string | undefined>()
  const [inpDescription,setInpDescription] = useState('') 
  const [inpValue,setInpValue ] = useState<number| undefined>() 
  const [inpDate,setInpDate ] = useState(new Date().toISOString().slice(0, 16)) 
  const [inpBankId,setInpBankId ] = useState('') 
  const [inpInstallments,setInpInstallments ] = useState<number| undefined>() 
  const [inpCurrentIntallments,setInpCurrentIntallments ] = useState<number| undefined>() 
  const [inpNfNumber,setInpNfNumber ] = useState('') 
  const [inpNfLink,setInpNfLink ] = useState('') 
  const [inpIsPaid, setInpIsPaid] = useState(false)
  const [isUpdateResource, setIsUpdateResource] = useState(false)

  const handleNotificationPopup = (msg: string, timeout = 3000) => {
    setShowNotificationPopup(true)
    setMsgNotificationPopup(msg)
    setTimeout(() => {
      setShowNotificationPopup(false)
    }, timeout);
  }

  const handleAlertPopup = (msg: string) => {
    setShowAlertPopup(true)
    setMsgAlertPopup(msg)
  }

  const handleSelectLimit = (event: { target: { value: SetStateAction<string> } }) => {
    const value = event.target.value as string
    const newLimit = parseInt(value)
    setLimit(newLimit);
    const newTotalPages = calcPages(total, newLimit)
    setTotalPages(newTotalPages)
    setPage(page > newTotalPages? newTotalPages : page)

  };

  const handleSelectBankId = (event: { target: { value: SetStateAction<string> } }) => {
    const value = event.target.value as string
    if(value !=="") {
      setInpBankId(value)
    } else if(bankAccounts.length) {
      setInpBankId(bankAccounts[0].id)
    }

  };

  const handleCreateResource = () => {
    setIsCreate(!isCreate)
    setIsUpdateResource(false)
    resetInputs()
  }

  const handleClickDetails = (id: string) => {
    setResourceId(id)
    api.get(`/expenses/${id}`)
      .then(({ data }) => {
        console.log(data)
        const date =  new Date(data.date)
        date.setUTCHours(date.getUTCHours() - 3)

        setInpDescription(data.description)
        setInpValue(parseValueNumberByApi(data.value))
        setInpBankId(data.bankAccountId)
        setInpDate(date.toISOString().slice(0, 16))
        setInpInstallments(data.installments > 1? data.installments : undefined)
        setInpCurrentIntallments(data.currentInstallment > 1 ? data.currentInstallment : undefined)
        setInpIsPaid(data.isPaid)
        setIsEdit(!isEdit)
      })
      .catch(err=> {
       console.error(err)
       alert(err)
      })
  }

  const resetInputs = () => {
    setInpDescription('')
    setInpValue(undefined)
    setInpDate(new Date().toISOString().slice(0, 16))
    setInpInstallments(undefined)
    setInpCurrentIntallments(undefined)
    setInpNfNumber('')
    setInpNfLink('')
    setInpIsPaid(false)
  }

  const handleSaveResource = (id: string | undefined) => {
    const newExpense = {
      description: inpDescription,
      value: parseValueToApi(inpValue),
      date: new Date(inpDate),
      bankAccountId: inpBankId,
      installments: inpInstallments,
      currentInstallment: inpCurrentIntallments,
      nfNumber: inpNfNumber,
      nfLink: inpNfLink,
      isPaid: inpIsPaid
    }
    Object.keys(newExpense).forEach((field) => {
      if (newExpense[field as keyof typeof newExpense] === '' || newExpense[field as keyof typeof newExpense] === 0) {
        delete newExpense[field as keyof typeof newExpense];
      }
    })
    if((!newExpense.description || !newExpense.bankAccountId || !newExpense.value)) {
      setShowAlertPopup(true)
      setMsgAlertPopup('Field required not found')
    } else {
      const func = id ? api.patch(`/expenses/${id}`, newExpense) : api.post('/expenses', newExpense)
        func.then(({ data }) => {
          if(id) {
            handleNotificationPopup('Atualizado com sucesso', 1000)
            setResourceId(undefined)
            setIsEdit(false)
          } else {
            handleAlertPopup('Salvo com sucesso')
          }
          resetInputs()
          setIsUpdateResource(true)
        })
        .catch(err => {
          alert('Error :(')
          console.error(err)
        })
    }
  }

  const handleSetRemove = (isBool:boolean) => {
    setIsUpdateResource(isBool)
  }

  const handleConfirmPopup = ()  => {
    setShowAlertPopup(false)
    setIsErrorMsg(false)
  }

  useEffect(()=> {
    api.get(`/bankAccounts`)
      .then(({ data: { data } }) => {
        setBankAccounts(data)
        // setInpBankId(data[0].id)
      })  
      .catch(err=> {
        console.error(err)
      })
  }, [isCreate, isEdit])

  
  useEffect(()=> {
    api.get(`/expenses?limit=${limit}&page=${page}&${filter}`)
      .then(({ data: { data, count, sum: sumApi } }) => {
       setExpenses(data)
       setTotal(count)
       setSum(sumApi)
       setTotalPages(calcPages(count, limit))
       setIsUpdateResource(false)
      })
      .catch(err=> {
       console.error(err)
      })
  }, [limit, page, filter,isUpdateResource])
 
  return (
    <div className="resource">
      {
        showAlertPopup && 
        <AlertPopup
        message={msgAlertPopup}
        onConfirm={handleConfirmPopup}
        isError={isErrorMsg}
        />
      }

      {
        showNotificationPopup && 
        <NotificationPopup
        message={msgNotificationPopup}
        isError={isErrorNotification}
        />
      }
      <h1>Desepesas</h1>
    { !isCreate && 
      <div className="filters">
        <label>Quant. por Página: </label>
        <select id="limit" value={String(limit)} onChange={handleSelectLimit}>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    }

      <div className="div-item">
        {CreateExpenses(
          isEdit,
          isCreate,
          inpDescription,
          inpValue,
          inpDate,
          inpBankId,
          inpCurrentIntallments,
          inpInstallments,
          inpNfNumber,
          inpNfLink,
          setInpDescription,
          setInpValue,
          setInpDate,
          bankAccounts,
          setInpInstallments,
          setInpCurrentIntallments,
          setInpNfNumber,
          setInpNfLink,
          handleSelectBankId,
        )}
        <div className="div-buttons">
          {
            !isEdit ?
            <div className={!isCreate ? "button-add" : 'button-back'} onClick={handleCreateResource}>
              {isCreate ? <AiOutlineClose /> : <AiOutlinePlus />}
            </div>
            :
            <div className="button-back" onClick={e=>{
              setResourceId(undefined)
              setIsEdit(false)
              }}>
              <AiOutlineClose />
            </div>
          }
          { !isEdit ? 
            <div className={!isCreate ? "div-none" : 'button-save'} onClick={e=>handleSaveResource(resourceId)}>
              {isCreate ? <AiOutlineCheck /> : ''}
            </div>
            : 
            <div className="button-save" onClick={e => handleSaveResource(resourceId)}>
              <AiOutlineCheck />
            </div>
          }
        </div>
        {        
        !isCreate && !isEdit && expenses && expenses.length > 0 ?
        (
          <ul className="ul-list">
            <div className="info">
              <div>
                <label>Quant.: </label>
                <label>{total}</label>
              </div>
              <div>
              <label>Total: </label>
                <label>R$ {parseValueByApi(sum)}</label>
              </div>
            </div>
            <li className="li-title">
              <div className="item-date" title="Date">Data</div>
              <div className="item-description">Descrição</div>
              <div className="item-value">Valor</div>
              <div className="item-status" >Status</div>
              <div className="item-details">Detalhes</div>
              <div className="item-remove"></div>
            </li> 
          {
            expenses.map((expense, index) => <LiExpenses 
              index={index}
              resource={expense} 
              resources={expenses} 
              setResource={setExpenses} 
              setUpdateResource={handleSetRemove}
              setHandleEdit={handleClickDetails}
              />)
          }
          </ul>
        )
        : !isCreate && !isEdit &&
        (          
        <div>
          <p>No expenses found.</p>
        </div>
        )
      }
      </div>
      { !isCreate &&
      <div className="div-list-footer">            
        {page > 1 ? <span onClick={e => setPage(page - 1)}>{'<'}</span> : <div> </div>}
        <div className="div-list-footer-input">
        <input 
            type="number"
            value={page} 
            onChange={e=>setPage(Number(e.target.value))}
            min={1}
            max={totalPages} 
          />
          <label>{' / '}</label>
          <label>{totalPages}</label>
        </div>
        {page < totalPages ? <span onClick={e => setPage(page + 1)}>{'>'}</span> : <div> </div>}
      </div>
      }
    </div>
  );
}
