import axios from 'axios';
import { getToken } from "./auth";
const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://api.wis.tec.br' // 'https://financial-control-nest.onrender.com'

const api = axios.create({
  baseURL
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
