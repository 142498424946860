import { useParams } from "react-router-dom";

export const ExpensesById = () => {
  const { id } = useParams<{ id?: string }>();

  return(
    <div className="body">
      <p> Hello Expense {id}  </p>
    </div>
  )
}
