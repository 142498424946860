import React from 'react';
import '../../assets/css/footer.css'

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      
    </footer>
  );
};

