const parseDateByApi = (isoDate: string, isTime?: boolean) => {
const date = new Date(isoDate);
if(isTime) {
  return date.toLocaleString('pt-BR', {
    timeZone: 'America/Sao_Paulo' // Considera o fuso horário do Brasil
  }).toString()
}

  return date.toLocaleDateString('pt-BR', {
    timeZone: 'America/Sao_Paulo' // Considera o fuso horário do Brasil
  }).toString();
};

const parseValueByApi = (value: number) => (value !== 0 ? value / 100 : value)
  .toFixed(2)
  .replace('.', ',')

const parseValueNumberByApi = (value: number) => Number((value !== 0 ? value / 100 : value)
  .toFixed(2))

export {
  parseDateByApi,
  parseValueByApi,
  parseValueNumberByApi
}
