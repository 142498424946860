import { useParams } from "react-router-dom";
import { ExpensesList } from "./expenses-list";
import { ExpensesById } from "./expenses-by-id";
import api from "../../../services/api";
import { useEffect, useState } from "react";

export const Expenses = () => {
  const [apiOk, setApiOk] = useState('')
  useEffect(() => {
    api.get('/healthcheck').then(({status})=> {
      if(status === 200) {
        setApiOk('ok')
      }
    })
    .catch(err => setApiOk('error'))
  }, [])
  
  const { id } = useParams<{ id?: string }>();
  switch (apiOk) {
    case 'ok': 
      return !id ? <ExpensesList/> : <ExpensesById />
    case 'error': 
      return <div>ERROR</div>
    default: 
      return <div>Await API</div>
  }
}

export type ExpensesType = {
  id: string
  description: string
  value: number
  date: string
  createdAt: string
  updatedAt: string
  bankAccount: {
    id?: string,
    name?: string
  }
  categoryId?: string
  userId: string
  installments: number
  currentInstallment: number 
  nfNumber?: string
  nfLink?: string
  flags: string[]
  isPaid: boolean
  }
