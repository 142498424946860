import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { AuthProvider } from '../contexts';
import ProtectedRoute from './Protected-router';
import { 
  Login,
  BodyApp,
  Expenses,
  BankAccounts,
  QRCodeScanner
} from "../components";



export const RoutesApp = () => {
  return (
    <AuthProvider>
        <Router>
          <Routes>
            {/* Rota pública */}
            
            {/* Rotas protegidas */}
            <Route element={<ProtectedRoute />}>
              <Route path="/" element= {<BodyApp>home</BodyApp>}/>
              <Route path="/expenses" element= {<BodyApp><Expenses /></BodyApp>}/>
              <Route path="/bankAccounts/:id?" element= {<BodyApp><BankAccounts /></BodyApp>}/>
              <Route path="/qrcode" element= {<BodyApp><QRCodeScanner /></BodyApp>}/>
            </Route>

            {/* Rota de login */}
            <Route path="/login" element={
              <BodyApp> 
                <Login />
              </BodyApp>
              } />

            {/* Rota 404 */}
            <Route path="*" element={
              <BodyApp> 
                <h1>404 NOT FOUND</h1>
              </BodyApp>
              } />
          </Routes>
        </Router>
    </AuthProvider>
  );
};
