export const TOKEN_KEY = "my-token-api";
export const isAuthenticated = () => Boolean(localStorage.getItem(TOKEN_KEY));
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const setStorage = (variable: string, value: string) => localStorage.setItem(variable, value);
export const getStorage = (variable: string) => localStorage.getItem(variable);
export const cleanStorage = () => localStorage.clear()
