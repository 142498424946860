import React, { useEffect, useRef, useState } from 'react';
import { Html5QrcodeScanner, Html5Qrcode } from 'html5-qrcode';

export const QRCodeScanner: React.FC = () => {
  const [qrCodeData, setQrCodeData] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [cameras, setCameras] = useState<any[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<string | null>(null);
  const scannerRef = useRef<Html5QrcodeScanner | null>(null);

  useEffect(() => {
    // Listar as câmeras disponíveis ao carregar o componente
    Html5Qrcode.getCameras().then((devices) => {
      if (devices && devices.length) {
        setCameras(devices);
        setSelectedCamera(devices[0].id); // Seleciona a primeira câmera
      }
    }).catch((err) => {
      setError('Erro ao acessar câmeras: ' + err.message);
    });

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear();
      }
    };
  }, []);

  useEffect(() => {
    if (selectedCamera && !scannerRef.current) {
      scannerRef.current = new Html5QrcodeScanner(
        'reader', 
        { fps: 10, qrbox: 250 },
        false
      );
      scannerRef.current.render(
        (decodedText) => { 
          setError(null);
          setQrCodeData(decodedText); 
        },
        (err) => setError('Erro ao escanear: ' + err)
      );
    }
  }, [selectedCamera]);

  return (
    <div>
      <h2>QR Code Scanner</h2>
      <div id="reader" style={{ width: '100%' }}></div>
      
      {cameras.length > 0 && (
        <select onChange={(e) => setSelectedCamera(e.target.value)} value={selectedCamera || ''}>
          {cameras.map((camera) => (
            <option key={camera.id} value={camera.id}>
              {camera.label || `Camera ${camera.id}`}
            </option>
          ))}
        </select>
      )}

      <input 
        type="text" 
        value={qrCodeData || ''} 
        readOnly 
        placeholder="Dados do QR Code"
        style={{ width: '100%', marginTop: '10px' }} 
      />
      
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
    </div>
  );
};
