import React from 'react';
import '../../assets/css/popup.css'

interface ConfirmPopupProps {
  message: string;
  onConfirm: () => void;
  isError: boolean
}

export const AlertPopup: React.FC<ConfirmPopupProps> = ({ message, onConfirm, isError }) => {
  return (
    <div  className='container-popup'>
      <div className='popup'>
        <p>{message}</p>
        <div className='buttons-popup'>
          <button onClick={onConfirm} className={isError? 'confirmDeleteButton-popup' :'confirmButton-popup'}>OK</button>
        </div>
      </div>
    </div>
  );
};
