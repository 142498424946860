import React, { useState } from 'react';
import '../../assets/css/popup.css'

interface ConfirmPopupProps {
  message: string;
  isDelete: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ message, onConfirm, onCancel, isDelete }) => {
  return (
    <div  className='container-popup'>
      <div className='popup'>
        <p>{message}</p>
        <div className='buttons-popup'>
          <button onClick={onConfirm} className={isDelete? 'confirmDeleteButton-popup' : 'confirmButton-popup'}>Confirmar</button>
          <button onClick={onCancel} className='cancelButton-popup'>Cancelar</button>
        </div>
      </div>
    </div>
  );
};
