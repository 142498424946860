import { SetStateAction, useEffect, useState } from "react"
import api from '../../../services/api'
import { Link } from "react-router-dom"
import { parseDateByApi, parseValueByApi } from "../../../hooks"
import { BankAccountsType } from "./bank-accounts"
import '../../../assets/css/resource.css'
import { LiBankAccounts } from "../../Li"
import { AlertPopup, NotificationPopup } from "../../PopUps"
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from "react-icons/ai"
import { CreateBankAccounts } from "./create-bank-accounts"

export const BankAccountsList = () => {
  const [resources, setResources] = useState<BankAccountsType[]>([])
  const calcPages = (total: number, limit:number) => {
    const calc = Math.floor(total / limit) + (total % limit !== 0 ? 1 : 0)
    return calc || 1
  }
  const [total, setTotal] = useState(0)
  // const [sum, setSum] = useState(0)
  const [limit, setLimit] = useState(5)
  const [page, setPage] = useState(1)
  const [filter, /* setFilter */] = useState('')
  const [totalPages, setTotalPages] = useState(calcPages(total, limit))
  const [isCreate, setIsCreate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [isErrorNotification, setIsErrorNotification] = useState(false)
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [msgNotificationPopup, setMsgNotificationPopup] = useState('');

  const [isErrorMsg, setIsErrorMsg] = useState(false)
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [msgAlertPopup, setMsgAlertPopup] = useState(''); 

  const [resourceId, setResourceId] = useState<string | undefined>()
  const [inpName,setInpName] = useState('') 
  const [inpBestDay,setInpBestDay ] = useState<number| undefined>()
  const [inpCredit,setInpCredit ] = useState<number| undefined>()
  const [inpDueDay,setInpDueDay ] = useState<number| undefined>()
  const [inpLimitCredit,setLimitCredit ] = useState<number| undefined>()
  const [inpDebit,setInpDebit ] = useState<number| undefined>()
  const [isUpdateResource, setIsUpdateResource] = useState(false)

  const handleNotificationPopup = (msg: string, timeout = 3000) => {
    setShowNotificationPopup(true)
    setMsgNotificationPopup(msg)
    setTimeout(() => {
      setShowNotificationPopup(false)
    }, timeout);
  }

  const handleAlertPopup = (msg: string) => {
    setShowAlertPopup(true)
    setMsgAlertPopup(msg)
  }

  const handleSelectLimit = (event: { target: { value: SetStateAction<string> } }) => {
    const value = event.target.value as string
    const newLimit = parseInt(value)
    setLimit(newLimit);
    const newTotalPages = calcPages(total, newLimit)
    setTotalPages(newTotalPages)
    setPage(page > newTotalPages? newTotalPages : page)
  };

  const handleCreateResource = () => {
    setIsCreate(!isCreate)
    setIsUpdateResource(false)
    if(isCreate) {
      resetInputs()
    }
  }

  const handleClickDetails = (id: string) => {
    setIsEdit(!isEdit)
    setResourceId(id)
    api.get(`/bankAccounts/${id}`)
      .then(({ data }) => {
        console.log(data)
        setInpName(data.name)
        setInpBestDay(data.bestDay)
        setInpCredit(data.credit)
        setInpDueDay(data.dueDay)
        setLimitCredit(data.limitCredit)
        setInpDebit(data.debit)
      })
      .catch(err=> {
       console.error(err)
      })
  }

  const resetInputs = () => {
    setInpName('')
    setInpBestDay(undefined)
    setInpCredit(undefined)
    setInpDueDay(undefined)
    setLimitCredit(undefined)
    setInpDebit(undefined)
  }

  const handleSaveResource = (id: string | undefined) => {
    const newBankAccont = {
      name: inpName,
      bestDay: inpBestDay,
      // credit: inpCredit,
      dueDay: inpDueDay,
      // limitCredit: inpLimitCredit,
      // debit: inpDebit,
    }
    Object.keys(newBankAccont).forEach((field) => {
      if (newBankAccont[field as keyof typeof newBankAccont] === '' || !newBankAccont[field as keyof typeof newBankAccont]) {
        delete newBankAccont[field as keyof typeof newBankAccont];
      }
    })
    if((!newBankAccont.name)) {
      setShowAlertPopup(true)
      setMsgAlertPopup('Field required not found')
    } else {
      const func = id ? api.patch(`/bankAccounts/${id}`, newBankAccont) : api.post('/bankAccounts', newBankAccont)
        func.then(({ data }) => {
          if(id) {
            handleNotificationPopup('Atualizado com sucesso', 1000)
            setResourceId(undefined)
            setIsEdit(false)
          } else {
            handleAlertPopup('Salvo com sucesso')
          }
          resetInputs()
          setIsUpdateResource(true)
        })
        .catch(err => {
          alert('Error :(')
          console.error(err)
        })
    }
  }

  const handleSetRemove = (isBool:boolean) => {
    setIsUpdateResource(isBool)
  }

  const handleConfirmPopup = ()  => {
    setShowAlertPopup(false)
    setIsErrorMsg(false)
  }

  useEffect(()=> {
    api.get(`/bankAccounts?limit=${limit}&page=${page}&${filter}`)
      .then(({ data: { data, count } }) => {
        setResources(data)
        setTotal(count)
      })
      .catch(err=> {
        console.error(err)
        return []
      })
  }, [limit, page, filter, isUpdateResource])
 
  return (
    <div className="resource">
      {
        showAlertPopup && 
        <AlertPopup
        message={msgAlertPopup}
        onConfirm={handleConfirmPopup}
        isError={isErrorMsg}
        />
      }

      {
        showNotificationPopup && 
        <NotificationPopup
        message={msgNotificationPopup}
        isError={isErrorNotification}
        />
      }
      <h1>Cartões/Bancos</h1>

      { !isCreate && 
        <div className="filters">
          <label>Quant. por Página: </label>
          <select id="limit" value={String(limit)} onChange={handleSelectLimit}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      }

      <div className="div-item">
      {CreateBankAccounts(
          isEdit,
          isCreate,
          inpName,
          inpBestDay,
          inpCredit,
          inpDueDay,
          inpLimitCredit,
          inpDebit,
          setInpName,
          setInpBestDay,
          setInpCredit,
          setInpDueDay,
          setLimitCredit,
          setInpDebit        
        )}
        <div className="div-buttons">
          {
            !isEdit ?
            <div className={!isCreate ? "button-add" : 'button-back'} onClick={handleCreateResource}>
              {isCreate ? <AiOutlineClose /> : <AiOutlinePlus />}
            </div>
            :
            <div className="button-back" onClick={e=>setIsEdit(false)}>
              <AiOutlineClose />
            </div>
          }
          { !isEdit ? 
            <div className={!isCreate ? "div-none" : 'button-save'} onClick={e=>handleSaveResource(resourceId)}>
              {isCreate ? <AiOutlineCheck /> : ''}
            </div>
            : 
            <div className="button-save" onClick={e => handleSaveResource(resourceId)}>
              <AiOutlineCheck />
            </div>
          }
        </div>
        { !isCreate && !isEdit && resources && resources.length > 0 ?
        (
          <ul className="ul-list">
            <li className="li-title">
              <div className="item-description">Descrição</div>
              <div className="item-value">Melhor dia</div>
              <div className="item-value">Vencimento</div>
              <div className="item-details">Detalhes</div>
              <div className="item-remove"></div>
            </li> 
          {
            resources.map((resource,index) => <LiBankAccounts 
              resource={resource} 
              resources={resources}
              setHandleEdit={handleClickDetails}
              setResource={setResources}
              setUpdateResource={handleSetRemove}
              index={index}
            />)
          }
          </ul>
        )
        : !isCreate && !isEdit &&
        (          
        <div>
          <p>No expenses found.</p>
        </div>
        )
      }
      </div>
      { !isCreate &&
      <div className="div-list-footer">            
        {page > 1 ? <span onClick={e => setPage(page - 1)}>{'<'}</span> : <div> </div>}
        <label>Page: {page}/{totalPages}</label>
        {page < totalPages ? <span onClick={e => setPage(page + 1)}>{'>'}</span> : <div> </div>}
      </div>
      }
    </div>
  );
}
